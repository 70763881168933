import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { CardContainer, CircleLoader, Colorway, H4, Icon,P } from '@driftt/tide-core';

import MetricsContext from 'context/MetricsContext';
import useViewsData from 'hooks/useViewsData';

import PaginationControls from './PaginationControls';
import TotalViews from './TotalViews';
import ViewsTable from './ViewsTable';

import './styles.css';

function ViewerStats({ loading, videoData, videoId }) {
  const { addBreadcrumb } = useContext(MetricsContext);

  const { getNextPage, getPrevPage, page, results, totalViews, viewsLoading } = useViewsData({
    videoId,
  });

  useEffect(() => {
    if (!loading && !viewsLoading) {
      addBreadcrumb({ category: 'ui.load', message: 'Viewer stats loaded' });
    }
  }, [loading, viewsLoading, addBreadcrumb]);

  if (loading || viewsLoading) {
    return (
      <CardContainer className="viewer-stats">
        <div className="viewer-stats__loading">
          <CircleLoader size="large" colorMode="dark" />
        </div>
      </CardContainer>
    );
  }

  return (
    <CardContainer className={cx('viewer-stats', { empty: totalViews === 0 })} padding="large">
      <H4 className="viewer-stats__header">
        <TotalViews value={totalViews} />
      </H4>
      {totalViews === 0 ? (
        <div className="no-views-wrapper">
          <Colorway className="colorway" size="medium">
            <Icon name="cw-video" />
          </Colorway>
          <H4 className="header">No views yet</H4>
          <P className="viewer-stats__no-views-copy">
            Copy the link to your video to share with others via email or the platform of your
            choice.
          </P>
        </div>
      ) : (
        <>
          <ViewsTable
            results={results}
            duration={
              videoData.endTime ? videoData.endTime - videoData.startTime : videoData.duration
            }
          />
          <PaginationControls
            getNextPage={getNextPage}
            getPrevPage={getPrevPage}
            page={page}
            totalViews={totalViews}
          />
        </>
      )}
    </CardContainer>
  );
}

ViewerStats.propTypes = {
  loading: PropTypes.bool.isRequired,
  videoData: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }),
  videoId: PropTypes.number.isRequired,
};

export default ViewerStats;
