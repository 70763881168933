import React, { useContext, useEffect, useState } from 'react';

import disableInfoIcon from 'assets/svg/disabled-info-icon.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon,Input, ToolTip } from '@driftt/tide-core';

import { getMeetingsStatus } from 'api';
import { UserContext } from 'context/UserContext';

import CalendarMessage from './CalendarMessage';

import './styles.css';

function BotMeetingControl({
  botMeetingPlaybookEnabled,
  onBotMeetingControlChange,
  disableMeetingsToggle,
}) {
  const [calendarStatus, setCalendarStatus] = useState(false);
  const { hasUnifiedSeatExperience } = useContext(UserContext);

  useEffect(() => {
    if (!disableMeetingsToggle) {
      getMeetingsStatus().then((res) => {
        setCalendarStatus(res?.data?.connected);
      });
    }
  }, [disableMeetingsToggle, setCalendarStatus]);

  return (
    <div>
      <div className="toggle-wrapper">
        <Input
          type="checkbox"
          checked={botMeetingPlaybookEnabled && !disableMeetingsToggle}
          disabled={disableMeetingsToggle}
          onChange={() => onBotMeetingControlChange(!botMeetingPlaybookEnabled)}
        />
        <p
          className={cx('toggle-text', {
            'disabled-bot-meetings': disableMeetingsToggle,
          })}
        >
          Allow viewers to book meetings with my bot
        </p>
        <div className="toggle-tooltip">
          <ToolTip
            tethered
            flow="up"
            render={() => (
              <div>
                Our bot will surface your calendar availability so viewers can
                <br />
                book meetings even when you’re unavailable to chat.
                {disableMeetingsToggle && (
                  <>
                    <br />
                    <br />
                    Chat must be enabled for viewers to book meetings.
                  </>
                )}
              </div>
            )}
          >
            {disableMeetingsToggle ? (
              <img
                className="disabled-toggle"
                src={disableInfoIcon}
                alt=""
              />
            ) : (
              <Icon name="info" height="14" />
            )}
          </ToolTip>
        </div>
      </div>
      {!disableMeetingsToggle && (
        <CalendarMessage
          calendarConnected={calendarStatus}
          hasUnifiedSeatExperience={hasUnifiedSeatExperience}
        />
      )}
    </div>
  );
}

BotMeetingControl.propTypes = {
  botMeetingPlaybookEnabled: PropTypes.bool,
  onBotMeetingControlChange: PropTypes.func,
  disableMeetingsToggle: PropTypes.bool,
};

export default BotMeetingControl;
