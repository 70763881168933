import { VideoSource } from 'types/interfaces';

export const getCaptionsFileName = (videoSources: VideoSource[]) => {
  const sources = videoSources.map((source) => {
    const url = new URL(source.src);
    return url.pathname;
  });

  sources.sort(function (a) {
    if (a.includes('.mp4')) {
      return -1;
    } else {
      return 1;
    }
  });
  if (videoSources.length) {
    const videoSource = sources[0];
    if (videoSource.includes('.mp4')) {
      return videoSource.replace('.mp4', `.vtt`).substr(1);
    } else {
      return videoSource.replace('.webm', `.vtt`).substr(1);
    }
  }
};
