import * as React from 'react';

import scissorsCutIcon from 'assets/svg/scissors-cut.svg';
import trendingLineIcon from 'assets/svg/trending-line.svg';

import { CardContainer, H4, Icon } from '@driftt/tide-core';

import { EDIT_CONTROL_TYPE } from 'utils/constants';

import './VideoEditControls.css';

interface VideoEditControlsProps {
  onSectionClick: (section: string) => void;
}

const VideoEditControls: React.FC<VideoEditControlsProps> = ({ onSectionClick }) => {
  return (
    <CardContainer className="video-edit-controls-card" padding="none">
      <H4 className="edit-header">Edit my video</H4>
      <div
        className="edit-section bot-playbook-controls"
        onClick={() => {
          onSectionClick(EDIT_CONTROL_TYPE.BOT_PLAYBOOK_CONTROLS);
        }}
      >
        <div className="bot-playbook-header-wrapper header">
          <img
            className="trending-line-icon"
            src={trendingLineIcon}
            alt="trending-line.svg"
          />
          <H4 className="bot-playbook-header">General settings</H4>
        </div>
        <p className="description">Control how viewers engage with your video and bot</p>
      </div>
      <div className="line-separator"></div>
      <div
        className="edit-section"
        onClick={() => {
          onSectionClick(EDIT_CONTROL_TYPE.AUDIO_AND_CAPTIONS);
        }}
      >
        <Icon name="megaphone-2" width={15} height={15}></Icon>
        <H4 className="header">Audio and captions</H4>
      </div>
      <div className="line-separator"></div>
      <div
        className="edit-section"
        onClick={() => {
          onSectionClick(EDIT_CONTROL_TYPE.THUMBNAILS);
        }}
      >
        <Icon className="thumbnail-icon" name="photo" width={16} height={11}></Icon>
        <H4 className="header">Thumbnail</H4>
      </div>
      <div className="line-separator"></div>
      <div
        className="edit-section"
        onClick={() => {
          onSectionClick(EDIT_CONTROL_TYPE.TRIM_VIDEO);
        }}
      >
        <div className="trim-video-header-wrapper">
          <img
            className="scissors-icon"
            src={scissorsCutIcon}
            alt="scissors-cut.svg"
          />
          <H4 className="trim-video-header">Trim video</H4>
        </div>
      </div>
    </CardContainer>
  );
};

export default VideoEditControls;
