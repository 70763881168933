import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  CardContainer,
  CircleLoader,
  Footer,
  H4,
  H5,
  QuickActionButton,
} from '@driftt/tide-core';

import { fetchPrivacyGroupPermissions } from 'api';
import BackArrow from 'components/backArrow';
import LinkPrivacySelector from 'components/linkPrivacySelector';
import PrivacyModal from 'components/privacyModal';
import MetricsContext from 'context/MetricsContext';

import {
  getSelectedOption,
  toEmailPermissions,
  toOptions,
  toPermissions,
} from './permissionsUtils';

import './styles.css';

function LinkPrivacyEditor({
  onCloseClick,
  onPermissionsChange,
  onPrivacyGroupCreated,
  onPrivacyGroupUpdated,
  setDisableChatOption,
  permissions,
  privacyGroups,
  privacyGroupsLoading,
  videoVisibility,
  onVideoVisibilityChange,
}) {
  const groupToEdit = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initiallySelected = getSelectedOption(permissions);
  const [currentlySelected, setCurrentlySelected] = useState(initiallySelected);
  const { addBreadcrumb } = useContext(MetricsContext);
  const [isVisibleToAll] = useState(Boolean(videoVisibility?.visibleToAll));
  const [linkPrivacyAdjusted, setLinkPrivacyAdjusted] = useState(false);

  useEffect(() => {
    addBreadcrumb({ category: 'ui.open', message: 'Open link privacy editor' });
  }, [addBreadcrumb]);

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectionChange = (value) => {
    setCurrentlySelected(value);
    setLinkPrivacyAdjusted(true);
  };

  const saveSelectionChange = () => {
    if (currentlySelected !== initiallySelected) {
      onPermissionsChange(toPermissions(currentlySelected));
    }

    if (isVisibleToAll !== Boolean(videoVisibility?.visibleToAll)) {
      onVideoVisibilityChange({ visibleToAll: isVisibleToAll });
    }

    onCloseClick();
  };

  const handleEmailListChange = (emails) =>
    onPermissionsChange([{ source: 'self' }, ...toEmailPermissions(emails)]);

  const handleAddGroupClick = () => {
    groupToEdit.current = null;
    setIsModalOpen(true);
  };

  const handleEditGroupClick = async (group) => {
    try {
      const permissions = await fetchPrivacyGroupPermissions({
        id: group.value,
      });
      const emailsAndDomainsList = permissions.map((p) => p.value[p.source]);
      groupToEdit.current = { emailsAndDomainsList, ...group };
      setIsModalOpen(true);
    } catch (e) {
      Error(e);
    }
  };

  const handleAddNewCustomPrivacy = (name, emails) => {
    onPrivacyGroupCreated({
      name,
      permissions: toEmailPermissions(emails),
    });
    setIsModalOpen(false);
  };

  const handleUpdateGroup = (id, nextName, nextEmails) => {
    const prevName = groupToEdit.current.groupName;
    const prevEmails = groupToEdit.current.emailsAndDomainsList;

    const name = nextName !== prevName ? nextName : null;
    const permissions = nextEmails !== prevEmails ? toEmailPermissions(nextEmails) : null;

    onPrivacyGroupUpdated({ id, name, permissions });
    setIsModalOpen(false);
  };

  const updateDisableChatOptionAndGoBack = () => {
    setDisableChatOption(false);
    onCloseClick({ chatUpdate: true });
  };

  return (
    <CardContainer padding="large" className="link-privacy-editor-container">
      <BackArrow onClick={onCloseClick} />
      <H4 className="general-settings-header">Link privacy</H4>
      <H5 className="link-privacy-editor__subheader">Who can view this video?</H5>
      {privacyGroupsLoading ? (
        <div className="link-privacy-editor__loading">
          <CircleLoader size="large" />
        </div>
      ) : (
        <LinkPrivacySelector
          className="link-privacy-editor__selector"
          parentPage="SHARE"
          onChange={handleSelectionChange}
          onDisableChatClick={updateDisableChatOptionAndGoBack}
          onEmailListChange={handleEmailListChange}
          onEditGroupClick={handleEditGroupClick}
          options={toOptions({ permissions, privacyGroups })}
          selectedOption={currentlySelected}
        />
      )}
      <QuickActionButton
        className="link-privacy-editor__add-group-button"
        text="Add a privacy group"
        onClick={handleAddGroupClick}
      />
      {linkPrivacyAdjusted && (
        <Footer className="link-privacy-editor__footer">
          <Button
            className="link-privacy-editor__save-button"
            type="primary"
            onClick={saveSelectionChange}
          >
            Save
          </Button>
          <Button
            className="link-privacy-editor__cancel-button"
            type="tertiary"
            onClick={onCloseClick}
          >
            Cancel
          </Button>
        </Footer>
      )}
      {isModalOpen && (
        <PrivacyModal
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          editGroup={groupToEdit.current}
          addNewCustomPrivacy={handleAddNewCustomPrivacy}
          updateGroupCallback={handleUpdateGroup}
          groupNamesList={privacyGroups.map((group) => group.teamName)}
        />
      )}
    </CardContainer>
  );
}

LinkPrivacyEditor.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.object,
    }),
  ),
  onPermissionsChange: PropTypes.func.isRequired,
  onPrivacyGroupCreated: PropTypes.func.isRequired,
  onPrivacyGroupUpdated: PropTypes.func.isRequired,
  setDisableChatOption: PropTypes.func.isRequired,
  privacyGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      teamName: PropTypes.string.isRequired,
    }),
  ),
  privacyGroupsLoading: PropTypes.bool.isRequired,
  videoVisibility: PropTypes.shape({
    visibleToAll: PropTypes.bool.isRequired,
  }),
  onVideoVisibilityChange: PropTypes.func.isRequired,
};

export default LinkPrivacyEditor;
