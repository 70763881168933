import * as React from 'react';
import { useContext,useEffect, useState } from 'react';

import { PrivacyGroup, VideoData,VideoPermission } from 'types/interfaces';

import { H4, H5,Input } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';

import CopyLinkAndGifButton from './CopyLinkAndGifButton';
import LinkPrivacy from './LinkPrivacy';
import UpgradeCTA from './UpgradeCTA';

import './SharingOptions.css';

interface SharingOptionsProps {
  onEditLinkPrivacyClick: () => void;
  permissions: VideoPermission[];
  permissionsLoading: boolean;
  privacyGroups: PrivacyGroup[];
  privacyGroupsLoading: boolean;
  videoData: VideoData;
  hasSentVideoToDrift: { current: boolean };
  setShowOnboardingState: (value: boolean) => void;
}

const SharingOptions: React.FC<SharingOptionsProps> = ({
  onEditLinkPrivacyClick,
  permissions,
  permissionsLoading,
  privacyGroups,
  privacyGroupsLoading,
  videoData,
  hasSentVideoToDrift,
  setShowOnboardingState,
}) => {
  const { user } = useContext(UserContext);
  const { thumbnails, publicUrl, title } = videoData;
  const [selectedThumbnail, setSelectedThumbnail] = useState<string>('');

  useEffect(() => {
    thumbnails.forEach((thumbnail) => {
      if (videoData.thumbnail) {
        if (videoData.thumbnail === thumbnail.src) {
          setSelectedThumbnail(thumbnail.src);
        }
      } else {
        if (thumbnail.default) {
          setSelectedThumbnail(thumbnail.src);
        }
      }
    });
  }, [videoData.thumbnail, thumbnails]);

  return (
    <div className="sharing-options">
      <H4 className="sharing-options__header">Share with others</H4>
      <H5 className="sharing-options__link-header">Sharing link</H5>
      <Input
        className="sharing-options__link"
        readOnly
        value={publicUrl}
        onClick={(e) => e.target.select()}
      />
      <div className="copy-link-and-gif-button-wrapper">
        <CopyLinkAndGifButton
          publicUrl={publicUrl}
          videoTitle={title}
          thumbnailUrl={selectedThumbnail}
          setShowOnboardingState={setShowOnboardingState}
        />
      </div>
      {user?.driftUserData.hasVideoPrivacy ? (
        <LinkPrivacy
          loading={permissionsLoading || privacyGroupsLoading}
          onEditLinkPrivacyClick={onEditLinkPrivacyClick}
          permissions={permissions}
          privacyGroups={privacyGroups}
          videoVisibility={videoData.videoVisibility}
        />
      ) : (
        <UpgradeCTA />
      )}
    </div>
  );
};

export default SharingOptions;
