import React, { useContext } from 'react';

import { A } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';

import './UpgradeCTA.css';

function UpgradeCTA() {
  const { createUrlPath } = useContext(UserContext);

  return (
    <div className="video-sharing-upgrade-cta">
      Control who has access to your videos with{' '}
      <A href={createUrlPath('/settings/billing/')} target="_blank" rel="noopener noreferrer">
        Drift Video Pro
      </A>
      .
    </div>
  );
}

export default UpgradeCTA;
