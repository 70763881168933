import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Thumbnails } from 'types/interfaces';

import { Button, CardContainer, DefaultContainer, H4 } from '@driftt/tide-core';

import BackArrow from 'components/backArrow';

import CustomThumbnailCard from './CustomThumbnailCard';
import ThumbnailCard from './ThumbnailCard';
import ThumbnailUploader from './ThumbnailUploader';

import './styles.css';

interface ThumbnailPickerProps {
  videoId: number;
  thumbnails: Thumbnails[];
  unfurlThumbnail: Thumbnails;
  unsavedUnfurlThumbnail: Thumbnails;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
  onThumbnailPickerClose: () => void;
  onSave: (thumbnail: Thumbnails) => void;
  tempCustomThumbnailUrl: string;
  setTempCustomThumbnailUrl: (thumbnailUrl: string) => void;
  deletingExistingCustomThumbnail: boolean;
  setDeletingExistingCustomThumbnail: (isDeleting: boolean) => void;
}

const ThumbnailPicker: React.FC<ThumbnailPickerProps> = ({
  videoId,
  thumbnails,
  unsavedUnfurlThumbnail,
  unfurlThumbnail,
  setUnsavedUnfurlThumbnail,
  onThumbnailPickerClose,
  onSave,
  tempCustomThumbnailUrl,
  setTempCustomThumbnailUrl,
  deletingExistingCustomThumbnail,
  setDeletingExistingCustomThumbnail,
}) => {
  const customThumbnail =
    thumbnails.find((thumbnail) => {
      return thumbnail.type === 'custom_thumbnail';
    }) || null;
  const defaultThumbnailAfterDeletion =
    thumbnails.find((thumbnail) => {
      return thumbnail.type === 'static_image';
    }) ?? thumbnails[thumbnails.length - 1];

  return (
    <CardContainer className="thumbnail-card-container" padding="large">
      <BackArrow onClick={onThumbnailPickerClose} />
      <H4 className="thumbnail-header">Custom thumbnail</H4>
      <DefaultContainer className="thumbnail-cards-wrapper">
        {thumbnails.map((thumbnail) => {
          if (thumbnail.type !== 'custom_thumbnail') {
            return (
              <ThumbnailCard
                key={thumbnail.type}
                thumbnail={thumbnail}
                unsavedUnfurlThumbnail={unsavedUnfurlThumbnail}
                setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
              />
            );
          }
          // TODO: check if nothing breaks
          return null
        })}
        {customThumbnail && !deletingExistingCustomThumbnail ? (
          <CustomThumbnailCard
            unfurlThumbnail={unfurlThumbnail}
            customThumbnail={customThumbnail}
            tempCustomThumbnailUrl={tempCustomThumbnailUrl}
            setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
            unsavedUnfurlThumbnail={unsavedUnfurlThumbnail}
            setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
            setDeletingExistingCustomThumbnail={setDeletingExistingCustomThumbnail}
            defaultThumbnailAfterDeletion={defaultThumbnailAfterDeletion}
          />
        ) : (
          <ThumbnailUploader
            videoId={videoId}
            setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
            tempCustomThumbnailUrl={tempCustomThumbnailUrl}
            setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
            defaultThumbnailAfterDeletion={defaultThumbnailAfterDeletion}
          />
        )}
      </DefaultContainer>
      <CSSTransition
        // @ts-ignore
        in={tempCustomThumbnailUrl || unsavedUnfurlThumbnail?.type !== unfurlThumbnail?.type}
        timeout={300}
        classNames="captions-upload"
        unmountOnExit
      >
        <footer className="footer">
          <Button className="save" type="primary" onClick={onSave}>
            Save
          </Button>
          <Button className="cancel" type="tertiary" onClick={onThumbnailPickerClose}>
            Cancel
          </Button>
        </footer>
      </CSSTransition>
    </CardContainer>
  );
};

export default ThumbnailPicker;
