import * as React from 'react';
import { useState } from 'react';

import { Thumbnails, UploadedThumbnailFile } from 'types/interfaces';

import MakeSelection from '../../UploadThumbnailComponents/MakeSelection';
import UploadProgress from '../../UploadThumbnailComponents/UploadProgress';

import './styles.css';

interface ThumbnailUploaderProps {
  videoId: number;
  tempCustomThumbnailUrl: string;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
  setTempCustomThumbnailUrl: (thumbnailUrl: string) => void;
  defaultThumbnailAfterDeletion: Thumbnails;
}

const ThumbnailUploader: React.FC<ThumbnailUploaderProps> = ({
  videoId,
  tempCustomThumbnailUrl,
  setUnsavedUnfurlThumbnail,
  setTempCustomThumbnailUrl,
  defaultThumbnailAfterDeletion,
}) => {
  const [file, setFile] = useState<UploadedThumbnailFile | null>(null);
  const [showUploadProgress, setShowUploadProgress] = useState(false);

  return (
    <div className="upload-thumbnail-wrapper">
      {!showUploadProgress ? (
        <MakeSelection
          file={file}
          setFile={setFile}
          onUploadingThumbnail={() => setShowUploadProgress(true)}
          setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
          setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
          defaultThumbnailAfterDeletion={defaultThumbnailAfterDeletion}
        />
      ) : (
        <UploadProgress
          file={file}
          onThumbnailUrlRetrieved={() => setShowUploadProgress(false)}
          setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
          tempCustomThumbnailUrl={tempCustomThumbnailUrl}
          setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
          videoId={videoId}
        />
      )}
    </div>
  );
};

export default ThumbnailUploader;
