import * as React from 'react';
import { useCallback } from 'react';

import { Thumbnails } from 'types/interfaces';

import { Card } from '@driftt/tide-core';

import './styles.css';

interface ThumbnailCardProps {
  thumbnail: Thumbnails;
  unsavedUnfurlThumbnail: Thumbnails;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
}

const MemoizedCard = React.memo(Card);

const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  thumbnail,
  unsavedUnfurlThumbnail,
  setUnsavedUnfurlThumbnail,
}) => {
  const cardDescription = {
    gif_with_duration: 'GIF with CTA',
    default_gif: 'GIF',
    static_image: 'Static image',
    captions: 'GIF with captions',
  };
  return (
    <MemoizedCard
      className="thumbnail-card"
      imgSrc={useCallback(() => {
        return (
          <div className="thumbnail-wrapper">
            <img className="thumbnail-image" src={thumbnail.src} alt=""></img>
          </div>
        );
      }, [thumbnail.src])}
      key={thumbnail.type}
      selected={thumbnail.type === unsavedUnfurlThumbnail?.type}
      onClick={useCallback(() => {
        setUnsavedUnfurlThumbnail(thumbnail);
      }, [thumbnail, setUnsavedUnfurlThumbnail])}
      description={cardDescription[thumbnail.type]}
      disabled={false}
    />
  );
};

export default React.memo(ThumbnailCard);
