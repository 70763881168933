import React from 'react';

import noCalendarIcon from 'assets/svg/no-calendar-error-icon.svg';
import PropTypes from 'prop-types';

import './styles.css';

function CalendarMessage({ calendarConnected, hasUnifiedSeatExperience }) {
  if (calendarConnected) {
    return (
      <div className="meetings-calendar-message-wrapper">
        <span role="img" aria-label="point-emoji">
          👉
        </span>
        <div className="message-text">
          Customize your meetings and calendar status. &nbsp;
          <a
            className="message-link"
            href={
              hasUnifiedSeatExperience
                ? 'https://app.drift.com/meetings/mine'
                : 'https://video.drift.com/settings/meetings'
            }
          >
            Review settings
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="meetings-calendar-message-wrapper-error">
      <img
        className="error-icon"
        src={noCalendarIcon}
        alt=""
      />
      <div className="message-text-error">
        {`Looks like we're missing your calendar.`}
        <a
          className="message-link-error"
          href={
            hasUnifiedSeatExperience
              ? 'https://app.drift.com/meetings/mine'
              : 'https://video.drift.com/settings/meetings'
          }
        >
          Sync my calendar
        </a>
      </div>
    </div>
  );
}

CalendarMessage.propTypes = {
  calendarConnected: PropTypes.bool,
  hasUnifiedSeatExperience: PropTypes.bool,
}

export default CalendarMessage;
