import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '@driftt/tide-core';

import './CreateGifButtons.css';

function CreateGifButtons({ onSaveClick, onCancelClick }) {
  return (
    <div className="create-gif-buttons">
      <Button className="cancel-button" type="tertiary" onClick={onCancelClick}>
        Cancel
      </Button>
      <Button className="save-button" type="secondary" onClick={onSaveClick}>
        Create GIF
      </Button>
    </div>
  );
}

CreateGifButtons.propTypes = {
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default CreateGifButtons;
