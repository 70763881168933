import React from 'react';

import PropTypes from 'prop-types';

import { Table } from '@driftt/tide-core';

import { formatRelativeTimeViewed, formatViewer, formatWatched } from './cellFormatters';

import './ViewsTable.css';

function ViewsTable({ results, duration }) {
  const dataRows = results.map((view) => [
    formatViewer(view),
    formatWatched(view, duration),
    formatRelativeTimeViewed(view.datetimeCreated),
  ]);

  return (
    <Table
      className="viewer-stats__table"
      columns={[
        { label: 'Viewer', width: '50%' },
        { label: '% watched', width: '30%' },
        { label: 'Viewed', width: '20%' },
      ]}
      data={[...dataRows]
        .map((row) => row.map((content) => ({ content })))
        .map((data) => ({ data }))}
    />
  );
}

ViewsTable.propTypes = {
  results: PropTypes.array.isRequired,
  duration: PropTypes.number.isRequired,
};

export default ViewsTable;
