import * as React from 'react';
import { useContext,useRef, useState } from 'react';

import { PlayerOptions, Thumbnails,VideoData } from 'types/interfaces';

import { CardContainer,CircleLoader } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';
import useLinkPrivacyData from 'hooks/useLinkPrivacyData';
import { EDIT_CONTROL_TYPE } from 'utils/constants';
import { segmentTrack } from 'utils/metrics';
import { captionsGenerated } from 'utils/playerUtils';

import VideoEditControls from '../VideoEditControls';

import AudioAndCaptions from './AudioAndCaptions';
import { getCaptionsFileName } from './AudioAndCaptions/utils';
import AudioControl from './AudioControl/AudioControl';
import BotPlaybookControls from './BotPlaybookControls';
import LinkPrivacyEditor from './LinkPrivacyEditor';
import OnboardingMessage from './OnboardingMessage';
import SharingOptions from './SharingOptions';
import ThumbnailPicker from './ThumbnailPicker';

import './styles.css';

interface VideoSharingProps {
  loading: true;
  videoData: VideoData;
  playerOptions: PlayerOptions;
  videoId: number;
  unfurlThumbnail: Thumbnails;
  thumbnails: Thumbnails[];
  isAudioIncluded: boolean;
  currentEditControl: string;
  unsavedUnfurlThumbnail: Thumbnails;
  tempCustomThumbnailUrl: string;
  setTempCustomThumbnailUrl: (thumbnailUrl: string) => void;
  onThumbnailUpload: (thumbnailUrl: string) => void;
  onThumbnailDelete: (thumbnailUrl: string) => void;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
  onAudioIncludedChange: (val: boolean) => void;
  onUnfurlThumbnailChange: (thumbnailUrl: string) => void;
  onPlayerOptionChange: (opts: PlayerOptions) => void;
  onTrimClick: () => void;
  onEditControlClick: (current: string | null) => void;
  videoVisibility: { visibleToAll: boolean };
  onVideoVisibilityChange: () => void;
}

const VideoSharing: React.FC<VideoSharingProps> = ({
  loading,
  videoData,
  playerOptions,
  videoId,
  unfurlThumbnail,
  thumbnails,
  isAudioIncluded,
  currentEditControl,
  unsavedUnfurlThumbnail,
  tempCustomThumbnailUrl,
  setTempCustomThumbnailUrl,
  onThumbnailUpload,
  onThumbnailDelete,
  setUnsavedUnfurlThumbnail,
  onAudioIncludedChange,
  onUnfurlThumbnailChange,
  onPlayerOptionChange,
  onTrimClick,
  onEditControlClick,
  videoVisibility,
  onVideoVisibilityChange,
}) => {
  const { videoSources } = videoData;
  const { user } = useContext(UserContext);
  const [showOnboardingState, setShowOnboardingState] = useState(!user?.hasCompletedOnboarding);
  const cameFromLinkPrivacy = useRef<boolean>(false);
  const hasSentVideoToDrift = useRef<boolean>(false);

  const [deletingExistingCustomThumbnail, setDeletingExistingCustomThumbnail] = useState(false);
  const staticThumbnailSrc =
    thumbnails.find((thumbnail) => {
      return thumbnail.type === 'static_image';
    })?.src ?? '';

  const {
    permissions,
    privacyGroups,
    permissionsLoading,
    privacyGroupsLoading,
    handlePermissionsChange,
    handlePrivacyGroupCreated,
    handlePrivacyGroupUpdated,
  } = useLinkPrivacyData({ videoId });

  const trackThumbnailsSectionClick = () => {
    const user = videoData.videoCreator;
    segmentTrack('Edit Thumbnails Section Clicked', {
      videoId: videoId,
      videoUrl: videoData.publicUrl,
      videoUserId: user.id,
      chatUserId: user.driftUserId,
    });
  };

  const updateUnfurlThumbnail = () => {
    const trackingProperties = {
      videoId: videoId,
      videoUrl: videoData.publicUrl,
      chosenThumbnailType: tempCustomThumbnailUrl
        ? 'custom_thumbnail'
        : unsavedUnfurlThumbnail.type,
    };
    if (tempCustomThumbnailUrl) {
      onThumbnailUpload(tempCustomThumbnailUrl);
      setTempCustomThumbnailUrl('');
      segmentTrack('Uploaded thumbnail', trackingProperties);
    }
    if (deletingExistingCustomThumbnail) {
      onThumbnailDelete(staticThumbnailSrc);
      setDeletingExistingCustomThumbnail(false);
    }
    onUnfurlThumbnailChange(unsavedUnfurlThumbnail.src);
    onEditControlClick(null);
    segmentTrack('Selected different default thumbnail', trackingProperties);
  };

  const cancelCloseThumbnailPicker = () => {
    if (tempCustomThumbnailUrl) {
      setTempCustomThumbnailUrl('');
    }
    if (deletingExistingCustomThumbnail) {
      setDeletingExistingCustomThumbnail(false);
    }
    setUnsavedUnfurlThumbnail(unfurlThumbnail);
    onEditControlClick(null);
  };

  const handleCloseLinkPrivacyClick = (opts = {} as any) => {
    if (opts.chatUpdate) {
      cameFromLinkPrivacy.current = true;
    }
    onEditControlClick(null);
  };

  const setDisableChatOption = (value) => {
    onPlayerOptionChange({ disable_chat: value });
  };

  const setCaptionEnabled = (value) => {
    onPlayerOptionChange({ has_captions: value });
  };

  const handleEditVideoSectionClick = (sectionName) => {
    if (sectionName === EDIT_CONTROL_TYPE.THUMBNAILS) {
      trackThumbnailsSectionClick();
    }
    onEditControlClick(sectionName);
  };

  const handlePermissionsChangeWithChatToggle = async (nextPermissions) => {
    await handlePermissionsChange(nextPermissions);
    if (nextPermissions.length) {
      try {
        setDisableChatOption(true);
      } catch (e) {
        Error((e as any).message);
      }
    }
  };

  if (loading || permissionsLoading) {
    return (
      <div className="video-sharing">
        <div className="video-sharing__loading">
          <CircleLoader size="large" colorMode="dark" />
        </div>
      </div>
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.PRIVACY) {
    return (
      <div className="video-sharing">
        <LinkPrivacyEditor
          onCloseClick={handleCloseLinkPrivacyClick}
          onPermissionsChange={handlePermissionsChangeWithChatToggle}
          onPrivacyGroupCreated={handlePrivacyGroupCreated}
          onPrivacyGroupUpdated={handlePrivacyGroupUpdated}
          setDisableChatOption={setDisableChatOption}
          permissions={permissions}
          privacyGroups={privacyGroups}
          privacyGroupsLoading={privacyGroupsLoading}
          videoVisibility={videoVisibility}
          onVideoVisibilityChange={onVideoVisibilityChange}
        />
      </div>
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.BOT_PLAYBOOK_CONTROLS) {
    return (
      <BotPlaybookControls
        onCloseClick={() => {
          onEditControlClick(null);
        }}
        playerOptions={playerOptions}
        permissions={permissions}
        permissionsLoading={permissionsLoading}
        onPlayerOptionChange={onPlayerOptionChange}
      />
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.AUDIO) {
    return (
      <AudioControl
        isAudioIncluded={isAudioIncluded}
        onAudioIncludedChange={onAudioIncludedChange}
        onAudioControlClose={() => {
          onEditControlClick(null);
        }}
      />
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.AUDIO_AND_CAPTIONS) {
    return (
      <AudioAndCaptions
        isCaptionsEnabled={playerOptions.has_captions}
        setCaptionEnabled={setCaptionEnabled}
        isAudioIncluded={isAudioIncluded}
        onAudioIncludedChange={onAudioIncludedChange}
        videoId={videoId}
        hasCaptionsProcessed={captionsGenerated(videoSources)}
        captionsFileName={getCaptionsFileName(videoSources)}
        onClose={() => {
          onEditControlClick(null);
        }}
      />
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.THUMBNAILS) {
    return (
      <ThumbnailPicker
        videoId={videoId}
        thumbnails={thumbnails}
        unfurlThumbnail={unfurlThumbnail}
        unsavedUnfurlThumbnail={unsavedUnfurlThumbnail}
        setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
        onThumbnailPickerClose={cancelCloseThumbnailPicker}
        onSave={updateUnfurlThumbnail}
        tempCustomThumbnailUrl={tempCustomThumbnailUrl}
        setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
        deletingExistingCustomThumbnail={deletingExistingCustomThumbnail}
        setDeletingExistingCustomThumbnail={setDeletingExistingCustomThumbnail}
      />
    );
  }

  if (currentEditControl === EDIT_CONTROL_TYPE.TRIM_VIDEO) {
    onTrimClick();
  }

  return (
    <div className="video-info">
      <CardContainer className="video-sharing" padding="none">
        {showOnboardingState && <OnboardingMessage />}
        <SharingOptions
          onEditLinkPrivacyClick={() => {
            onEditControlClick(EDIT_CONTROL_TYPE.PRIVACY);
          }}
          videoData={videoData}
          permissions={permissions as any}
          permissionsLoading={permissionsLoading}
          privacyGroups={privacyGroups as any}
          privacyGroupsLoading={privacyGroupsLoading}
          hasSentVideoToDrift={hasSentVideoToDrift}
          setShowOnboardingState={setShowOnboardingState}
        />
      </CardContainer>
      <VideoEditControls onSectionClick={handleEditVideoSectionClick} />
    </div>
  );
};

export default VideoSharing;
