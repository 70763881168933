import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '@driftt/tide-core';

import './TrimButtons.css';

function TrimButtons({ onSaveClick, onCancelClick }) {
  return (
    <div className="trim-buttons">
      <Button className="save-button" type="primary" onClick={onSaveClick}>
        Save
      </Button>
      <Button className="cancel-button" type="tertiary" onClick={onCancelClick}>
        Cancel
      </Button>
    </div>
  );
}

TrimButtons.propTypes = {
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default TrimButtons;
