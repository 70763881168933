import PropTypes from 'prop-types';

function TotalViews({ value }) {
  if (value === 0) {
    return '';
  }

  if (value === 1) {
    return '1 view';
  }

  return `${value} views`;
}

TotalViews.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TotalViews;
