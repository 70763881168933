import React from 'react';

import { msPerDay, msPerHour, msPerMinute, msPerMonth, msPerYear } from 'utils/constants';

export function formatViewer(view) {
  const viewedBy = view.viewedBy || {};
  const properties = view.properties || {};
  const email = viewedBy.email || properties.email;
  const companyName = properties.companyData && properties.companyData.name;

  if (email && companyName) {
    return (
      <div className="viewer-name">
        {email} ({companyName})
      </div>
    );
  }

  if (email) {
    return <div className="viewer-name">{email}</div>;
  }

  if (companyName) {
    return <div className="viewer-name">Site Visitor from {companyName}</div>;
  }

  const geo = properties.geo || {};
  const location = [geo.city, geo.country === 'US' ? geo.subdivision : geo.countryName]
    .filter(Boolean)
    .join(', ');

  if (location) {
    return <div className="viewer-name">Site Visitor from {location}</div>;
  }

  return <div className="viewer-name">Site Visitor</div>;
}

export function formatWatched(view, duration) {
  if (view.percentViewed === null) {
    return '--';
  }

  const seconds = (duration * view.percentViewed) / 100;

  const hh = (seconds / 3600) | 0;
  const mm = String(((seconds % 3600) / 60) | 0).padStart(hh ? 2 : 1, '0');
  const ss = String((seconds % 3600) % 60 | 0).padStart(2, '0');

  const timeViewed = [hh, mm, ss].filter(Boolean).join(':');

  return (
    <span>
      {view.percentViewed}% ({timeViewed})
    </span>
  );
}

export function formatRelativeTimeViewed(timestamp) {
  const timestampAsUtc = (timestamp + 'Z').replace(/ZZ$/, 'Z');
  const previous = Date.parse(timestampAsUtc);
  const current = new Date().getTime();

  if (previous > current) {
    return '--';
  }

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.floor(elapsed / 1000) + 's ago';
  }

  if (elapsed < msPerHour) {
    return Math.floor(elapsed / msPerMinute) + 'm ago';
  }

  if (elapsed < msPerDay) {
    return Math.floor(elapsed / msPerHour) + 'h ago';
  }

  if (elapsed < msPerMonth) {
    return Math.floor(elapsed / msPerDay) + 'd ago';
  }

  if (elapsed < msPerYear) {
    const value = Math.floor(elapsed / msPerMonth);
    return `${value} ${value === 1 ? 'month' : 'months'} ago`;
  }

  const value = Math.floor(elapsed / msPerYear);
  return `${value} ${value === 1 ? 'year' : 'years'} ago`;
}
