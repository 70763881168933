import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Button } from '@driftt/tide-core';

import MetricsContext from 'context/MetricsContext';
import copyToClipboard from 'utils/copyToClipboard';

import './styles.css';

function CopyLinkAndGifButton({ publicUrl, videoTitle, thumbnailUrl, setShowOnboardingState }) {
  const timer = useRef(null);
  const [justCopied, setJustCopied] = useState(false);
  const { trackCTA } = useContext(MetricsContext);

  const handleClick = () => {
    if (justCopied) {
      return;
    }

    trackCTA();
    copyToClipboard({ publicUrl, thumbnailUrl, videoTitle });
    setJustCopied(true);
    setShowOnboardingState(false);
    timer.current = setTimeout(() => setJustCopied(false), 3000);
  };

  useEffect(() => () => clearTimeout(timer.current), []);

  return (
    <Button className="copy-link-and-gif-button" onClick={handleClick}>
      {justCopied ? 'Copied!' : 'Copy link + GIF'}
    </Button>
  );
}

CopyLinkAndGifButton.propTypes = {
  publicUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  setShowOnboardingState: PropTypes.func,
};

export default CopyLinkAndGifButton;
