import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, CardContainer, H4,Toggle } from '@driftt/tide-core';

import './AudioControl.css';

function AudioControl({ isAudioIncluded, onAudioIncludedChange, onAudioControlClose }) {
  const [isAudioToggleOn, setIsAudioToggleOn] = useState(isAudioIncluded);

  const saveAudioOption = () => {
    onAudioIncludedChange(isAudioToggleOn);
    onAudioControlClose();
  };

  return (
    <CardContainer className="audio-card-container">
      <H4 className="audio-header">Audio</H4>
      <div className="toggle-wrapper">
        <Toggle
          checked={isAudioToggleOn}
          onChange={(e) => {
            setIsAudioToggleOn(e.target.checked);
          }}
        />
        <p className="toggle-text">Include audio</p>
      </div>
      <footer className="footer">
        <Button
          className="save"
          type="primary"
          disabled={isAudioIncluded === isAudioToggleOn}
          onClick={saveAudioOption}
        >
          Save
        </Button>
        <Button className="cancel" type="tertiary" onClick={onAudioControlClose}>
          Cancel
        </Button>
      </footer>
    </CardContainer>
  );
}
AudioControl.propTypes = {
  isAudioIncluded: PropTypes.bool.isRequired,
  onAudioIncludedChange: PropTypes.func.isRequired,
  onAudioControlClose: PropTypes.func.isRequired,
};

export default AudioControl;
