import { PrivacyGroup,VideoPermission } from 'types/interfaces';

export const getPrivacyDescription = ({
  permissions,
  privacyGroup,
  privacyGroups,
}: {
  permissions: VideoPermission[];
  privacyGroup: { value: { team: number } };
  privacyGroups: PrivacyGroup[];
  videoVisibility: { visibleToAll: boolean };
}): string => {
  const isVideoVisibleToAnyone = permissions?.length === 0;
  const isVideoVisibleToGroup = Boolean(privacyGroup);
  let linkText = '';

  if (isVideoVisibleToAnyone) {
    linkText = 'Anyone with the link can view this video';
  } else if (isVideoVisibleToGroup) {
    const id = privacyGroup.value.team;
    const team = privacyGroups?.find((group) => group.id === id);
    const groupName = team ? team.teamName : '<Unknown Group>';

    linkText = `Anyone at ${groupName} with the link can view this video`;
  } else {
    linkText = 'People with specific email addresses can view this video';
  }

  return linkText;
};
