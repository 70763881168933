import * as React from 'react';
import { useState } from 'react';

import { UploadedFile } from 'types/interfaces';

import { InlineWarning } from '@driftt/tide-core';
import { UploadArea } from '@driftt/tide-file-upload';

import { segmentTrack } from 'utils/metrics';

interface UploadCaptionsProps {
  videoId: number;
  setFile: (file: UploadedFile | null) => void;
  file?: any
}

const UploadCaptions = ({ file, setFile, videoId }: UploadCaptionsProps) => {
  const [errorText, setErrorText] = useState<string>('');
  return (
    <>
      <UploadArea
        className="upload-thumbnail-modal__file-upload"
        type="caption"
        onReadError={() => setErrorText('There was a problem reading the file.')}
        onTypeError={() => setErrorText('File extension not supported.')}
        onSizeError={() => setErrorText('File exceeds maximum file size of 20MB.')}
        handleSaveFile={() => {}}
        handleClearFile={() => {
          setFile(null);
        }}
        handleUploadFile={(file) => {
          setErrorText('');

          if (file.size === 0) {
            return Promise.reject(new Error('The file is empty.'));
          }

          setFile(file);
          segmentTrack('Caption Uploaded CTA Clicked', {
            type: file.type,
            fileTitle: file.name,
            videoId: videoId,
          });
          return Promise.resolve({ downloadUrl: file.preview });
        }}
        previewUrl={file && file.preview}
        previewName={file && file.name}
      />
      {errorText !== '' && <InlineWarning type="warning" copy={errorText} />}
    </>
  );
};
export default UploadCaptions;
