import pausePlayIcon from 'assets/svg/pause_play_icon.svg';
import rewindIcon from 'assets/svg/skip-back.svg';
import forwardIcon from 'assets/svg/skip-forward.svg';
import videojs from 'video.js';

import 'libraries/videojs-trimcontrols-plugin';
import 'libraries/videojs-crop';
import 'libraries/videojs.thumbnails';

export default function addHooksToVideoPlayer({ player, state, onAdjustTrim, onAdjustCrop }) {
  player.trimControlsPlugin({});
  player.cropPlugin({});

  player.on('volumechange', () => {
    if (state.isAudioDisabled) {
      player.volume(0);
    }
  });

  player.on('play', () => {
    player.removeChild('PauseControls');
  });

  player.on('loadedmetadata', () => {
    const { startTime, endTime } = state;

    player.trimControls.setValues(startTime ?? 0, endTime ?? Infinity);
    player.currentTime(startTime);
    player.trimControls.lock();

    if (startTime === null && endTime === null) {
      player.trimControls.hide();
    }
  });

  player.on('timeupdate', () => {
    if (player.abLoopPlugin.getOptions().enabled) {
      return;
    }

    // WORKAROUND: Use a tolerance for the floating point comparison.
    // player.currentTime() returns a value that uses 6 decimal places of
    // precision but the startTime/endTime values are higher precision.
    const epsilon = 0.000001;

    const startTime = state.inProgressTrimStart ?? state.startTime;
    const endTime = state.inProgressTrimEnd ?? state.endTime;
    const currentTime = player.currentTime();

    // Reset the video's current time if it falls outside of the trimmed range.
    if (startTime - currentTime > epsilon || currentTime - endTime > epsilon) {
      player.pause();
      player.currentTime(startTime ?? 0);
    }
  });

  player.on('TrimControlsMoved', (e, { start, end }) => {
    state.inProgressTrimStart = start;
    state.inProgressTrimEnd = end;
    onAdjustTrim({ startTime: start, endTime: end });
  });

  player.enableTrimMode = (startTime, endTime) => {
    state.startTime = startTime;
    state.endTime = endTime;
    state.inProgressTrimStart = startTime;
    state.inProgressTrimEnd = endTime;

    player.trimControls.setValues(startTime ?? 0, endTime ?? Infinity);
    player.options({ inactivityTimeout: 0 });
    player.trimControls.unlock();
    player.trimControls.show();
    player.play().then(() => player.pause());
  };

  player.disableTrimMode = (startTime, endTime) => {
    state.startTime = startTime;
    state.endTime = endTime;
    state.inProgressTrimStart = null;
    state.inProgressTrimEnd = null;

    player.trimControls.setValues(startTime ?? 0, endTime ?? Infinity);
    player.options({ inactivityTimeout: 2000 });
    player.trimControls.lock();

    if (state.startTime === null && state.endTime === null) {
      player.trimControls.hide();
    }
  };

  player.enableCreateGifMode = () => {
    return new Promise((resolve) => {
      const enable = () => {
        player.crop.videoWidth = player.videoWidth();
        player.crop.videoHeight = player.videoHeight();
        player.crop.show();
        player.abLoopPlugin.setOptions({ enabled: true });
        player.getChild('ControlBar').hide();
        state.prevVolume = player.volume();
        player.volume(0);
        player.one('timeupdate', () => player.abLoopPlugin.playLoop());

        resolve({
          duration: player.duration(),
        });
      };

      if (player.readyState() >= 1) {
        enable();
      } else {
        player.one('loadeddata', enable);
      }
    });
  };

  player.disableCreateGifMode = () => {
    player.crop.hide();
    player.abLoopPlugin.setOptions({ enabled: false });
    player.getChild('ControlBar').show();
    player.volume(state.prevVolume);
  };

  player.on('playerresize', () => {
    player.crop.resize(player.videoWidth(), player.videoHeight());
  });

  player.setGifStart = (gifStart) => {
    if (gifStart !== undefined) {
      player.abLoopPlugin.setStart(gifStart);
      player.currentTime(gifStart);
    }
  };

  player.setGifEnd = (gifEnd) => {
    if (gifEnd !== undefined) {
      player.abLoopPlugin.setEnd(gifEnd);
      player.currentTime(gifEnd - 0.5);
    }
  };

  player.on('CropControlsMoved', (e, values) => {
    const { cropX, cropY, cropWidth, cropHeight } = values;
    onAdjustCrop({ cropX, cropY, cropWidth, cropHeight });
  });

  player.on('pause', () => {
    if (player.abLoopPlugin.getOptions().enabled) {
      player.play();
      return;
    }

    const rewindElement = `<img id="rewind-icon" src="${rewindIcon}" />`;
    const pausePlayElement = `<img id="pause-play-icon" src="${pausePlayIcon}" />`;
    const forwardElement = `<img id="forward-icon" src="${forwardIcon}" />`;

    const Button = videojs.getComponent('Button');
    const PauseControls = videojs.extend(Button);
    videojs.registerComponent('PauseControls', PauseControls);

    player.addChild('PauseControls', {
      el: videojs.dom.createEl(
        'div',
        {
          className: 'video-pause-controls-container',
          id: 'pause-video-container',
          innerHTML: rewindElement + pausePlayElement + forwardElement,
        },
        {
          role: 'div',
        },
      ),
    });

    document.querySelector('#rewind-icon').onclick = () => {
      const nextTime = Math.max(state.startTime ?? 0, player.currentTime() - 5);
      player.currentTime(nextTime);
    };

    document.querySelector('#forward-icon').onclick = () => {
      const nextTime = Math.min(player.currentTime() + 5, state.endTime ?? player.duration());
      player.currentTime(nextTime);
    };

    document.querySelector('#pause-play-icon').onclick = () => player.play();
  });
}
