/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useContext, useEffect,useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import { A, Button, CardContainer, H4, Input,Ribbon } from '@driftt/tide-core';

import { fetchBotMessage } from 'api';
import BackArrow from 'components/backArrow';
import { UserContext } from 'context/UserContext';
import { openTeamsUpgradePlaybook } from 'utils';

import BotMeetingControl from './BotMeetingControl';
import DisableChatControl from './DisableChatControl';

import './styles.css';

function BotPlaybookControls({
  onCloseClick,
  playerOptions,
  permissions,
  permissionsLoading,
  onPlayerOptionChange,
}) {
  const { userHasFeature } = useContext(UserContext);
  const hasTeamsPlan = userHasFeature('HAS_TEAM_BOT_MEETINGS');
  const showBotMeetingsUpgradeState = !userHasFeature('HAS_ACTIVE_BOT_MEETINGS');
  const initialChatEnabled = !playerOptions.disable_chat;
  const initialBotMeetingPlaybookEnabled =
    playerOptions?.bot_meeting_playbook_enabled ?? hasTeamsPlan;
  const initialShowCreatedAtDate = playerOptions?.show_created_at_date ?? true;

  const [, setLoading] = useState(true);
  const [, setCurrentVideoMessage] = useState('');
  const [chatEnabled, setChatEnabled] = useState(initialChatEnabled);
  const [botMeetingPlaybookEnabled, setBotMeetingPlaybookEnabled] = useState(
    initialBotMeetingPlaybookEnabled,
  );
  const [showCreatedAtDate, setShowCreatedAtDate] = useState(initialShowCreatedAtDate);

  const isDirty =
    initialChatEnabled !== chatEnabled ||
    initialBotMeetingPlaybookEnabled !== botMeetingPlaybookEnabled ||
    initialShowCreatedAtDate !== showCreatedAtDate;

  const saveToggleStatuses = () => {
    onPlayerOptionChange({
      disable_chat: !chatEnabled,
      bot_meeting_playbook_enabled: botMeetingPlaybookEnabled,
      show_created_at_date: showCreatedAtDate,
    });

    onCloseClick();
  };

  const onCancel = () => {
    onCloseClick();
  };

  const fetchBotMessageData = useCallback(async () => {
    try {
      const botMessage = await fetchBotMessage();
      setCurrentVideoMessage(botMessage);
      setLoading(false);
    } catch (e) {
      Error(e);
    }
  }, []);

  useEffect(() => {
    fetchBotMessageData();
  }, []);

  return (
    <CardContainer className="bot-controls-card-container" padding="large">
      <BackArrow onClick={onCancel} />
      <H4 className="bot-controls-header">General settings</H4>
      <div className="chat-toggle-wrapper">
        <DisableChatControl
          permissions={permissions}
          permissionsLoading={permissionsLoading}
          onChatControlChange={() => {
            setChatEnabled((value) => !value);
          }}
          chatEnabled={chatEnabled}
          onPlayerOptionChange={onPlayerOptionChange}
        />
        <div className="meetings-calendar-message-wrapper">
          <span role="img" aria-label="point-emoji">
            👉
          </span>
          <div className="message-text">
            Customize your video bot's message.&nbsp;
            <a className="message-link" href="https://video.drift.com/settings/welcome-message">
              Edit message
            </a>
          </div>
        </div>
      </div>
      <div className="meetings-toggle-wrapper">
        {showBotMeetingsUpgradeState && (
          <div className="meetings-upgrade-cta-wrapper">
            <button className="link" onClick={openTeamsUpgradePlaybook}>
              <Ribbon isUpgrade={true} text="upgrade" />
            </button>
            <div className="text">
              Interested in using this feature?&nbsp;
              <A className="message-link" onClick={openTeamsUpgradePlaybook} type="secondary">
                Learn more about our paid features
              </A>
            </div>
          </div>
        )}
        <BotMeetingControl
          botMeetingPlaybookEnabled={botMeetingPlaybookEnabled}
          onBotMeetingControlChange={(value) => {
            setBotMeetingPlaybookEnabled(value);
          }}
          disableMeetingsToggle={showBotMeetingsUpgradeState || !chatEnabled}
        />
      </div>
      <div className="toggle-wrapper">
        <Input
          type="checkbox"
          checked={showCreatedAtDate}
          onChange={() => setShowCreatedAtDate(!showCreatedAtDate)}
        />
        <p className="toggle-text">Allow viewers to see the date the video was created</p>
      </div>
      <CSSTransition in={isDirty} timeout={300} classNames="captions-upload" unmountOnExit>
        <footer className="footer">
          <Button type="primary" onClick={saveToggleStatuses}>
            Save
          </Button>
          <Button type="tertiary" onClick={onCancel}>
            Cancel
          </Button>
        </footer>
      </CSSTransition>
    </CardContainer>
  );
}

BotPlaybookControls.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  playerOptions: PropTypes.shape({
    disable_chat: PropTypes.bool,
    bot_meeting_playbook_enabled: PropTypes.bool,
    show_created_at_date: PropTypes.bool,
  }).isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.object,
    }),
  ),
  permissionsLoading: PropTypes.bool.isRequired,
  onPlayerOptionChange: PropTypes.func.isRequired,
};

export default BotPlaybookControls;
