/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react';

import * as Sentry from '@sentry/browser';
import drift500Icon from 'assets/svg/drift_500.svg';
import PropTypes from 'prop-types';

import { DriftAnalytics } from '@driftt/dds.analytics';
import { Button, H1, H4 } from '@driftt/tide-core';

import ErrorBoundary from 'components/errorBoundary';

import redirectToLegacyPage from './redirectToLegacyPage';

import './TopLevelErrorBoundary.css';

function TopLevelErrorBoundary({ children }) {
  const exceptionId = useRef(null);

  // https://docs.sentry.io/platforms/javascript/react/#error-boundaries
  const captureException = (error, errorInfo) => {
    exceptionId.current = DriftAnalytics.captureException(error, errorInfo);
  };

  const handleShowReportClick = () => {
    Sentry.showReportDialog({ eventId: exceptionId.current });
  };

  return (
    <ErrorBoundary
      captureException={captureException}
      fallback={
        <div className="share-page-error">
          <div className="share-page-error__inner-container">
            <img src={drift500Icon} alt="drift-500-icon" />
            <H1 className="share-page-error__heading">Something's gone wrong!</H1>
            <H4 className="share-page-error__subheading">
              DriftBot would feel so embarrassed (if we programmed it to feel emotions){' '}
              <span role="img" aria-label="">
                😳
              </span>
            </H4>
            <div className="share-page-error__button-container">
              <Button onClick={handleShowReportClick}>Report feedback</Button>
              <Button type="secondary" onClick={() => redirectToLegacyPage()}>
                Take me back to the legacy page
              </Button>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
}

TopLevelErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default TopLevelErrorBoundary;
