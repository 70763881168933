import React from 'react';

import PropTypes from 'prop-types';

import { CircleLoader, H4,Icon } from '@driftt/tide-core';

import PeopleWithEmails from './PeopleWithEmails';
import { getPrivacyDescription } from './utils';

import './styles.css';

function LinkPrivacy({
  loading,
  onEditLinkPrivacyClick,
  permissions,
  privacyGroups = [],
  videoVisibility,
}) {
  const privacyGroup = permissions ? permissions.find((p) => p.source === 'Privacy Team') : null;

  const description = getPrivacyDescription({
    permissions,
    privacyGroup,
    privacyGroups,
    videoVisibility,
  });

  return (
    <div className="link-privacy" onClick={onEditLinkPrivacyClick}>
      <div className="link-privacy__header-container">
        <Icon className="link-privacy__link-icon" name="link-1" width={12} height={12} />
        <H4 className="link-privacy__header">Link privacy</H4>
      </div>
      <div className="link-privacy__current-setting">
        {loading ? (
          <div className="link-privacy__loading">
            <CircleLoader size="small" />
          </div>
        ) : permissions.length === 0 || privacyGroup ? (
          <div className="link-privacy__button-text">{description}</div>
        ) : (
          <PeopleWithEmails description={description} permissions={permissions} />
        )}
      </div>
    </div>
  );
}

LinkPrivacy.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEditLinkPrivacyClick: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.object,
    }),
  ),
  privacyGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      teamName: PropTypes.string.isRequired,
    }),
  ),
  videoVisibility: PropTypes.shape({
    visibileToAll: PropTypes.bool,
  }),
};

export default LinkPrivacy;
