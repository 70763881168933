import * as React from 'react';

import cx from 'classnames';
import { Thumbnails } from 'types/interfaces';

import './styles.css';

interface CustomThumbnailCardProps {
  customThumbnail: Thumbnails | null;
  unfurlThumbnail: Thumbnails;
  unsavedUnfurlThumbnail: Thumbnails;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
  tempCustomThumbnailUrl: string;
  setTempCustomThumbnailUrl: (thumbnailUrl: string) => void;
  setDeletingExistingCustomThumbnail: (isDeleting: boolean) => void;
  defaultThumbnailAfterDeletion: Thumbnails;
}

const CustomThumbnailCard: React.FC<CustomThumbnailCardProps> = ({
  customThumbnail,
  unsavedUnfurlThumbnail,
  setUnsavedUnfurlThumbnail,
  tempCustomThumbnailUrl,
  setTempCustomThumbnailUrl,
  setDeletingExistingCustomThumbnail,
  defaultThumbnailAfterDeletion,
}) => {
  return (
    <div
      className={cx('custom-thumbnail-wrapper', {
        selected: unsavedUnfurlThumbnail?.type === 'custom_thumbnail',
      })}
      onClick={() => {
        if (customThumbnail) {
          setUnsavedUnfurlThumbnail(customThumbnail);
        } else {
          setUnsavedUnfurlThumbnail({
            default: true,
            src: tempCustomThumbnailUrl,
            type: 'custom_thumbnail',
          });
        }
      }}
    >
      <div className="custom-thumbnail-background">
        <img
          className="thumbnail-image"
          src={tempCustomThumbnailUrl || customThumbnail?.src}
          alt=""
        ></img>
      </div>
      <div
        className="delete-custom-thumbnail-button"
        onClick={(e) => {
          e.stopPropagation();
          setTempCustomThumbnailUrl('');
          setUnsavedUnfurlThumbnail(defaultThumbnailAfterDeletion);
          setDeletingExistingCustomThumbnail(true);
        }}
      >
        Clear
      </div>
    </div>
  );
};

export default React.memo(CustomThumbnailCard);
