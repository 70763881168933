import React from 'react';

import PropTypes from 'prop-types';

import { IconButton } from '@driftt/tide-core';

import { pageSize } from 'utils/constants';

import './PaginationControls.css';

function PaginationControls({ getNextPage, getPrevPage, page, totalViews }) {
  const hasNextPage = page * pageSize < totalViews - pageSize;
  const hasPrevPage = page > 0;
  const pageStart = page * pageSize + 1;
  const pageEnd = Math.min(totalViews, pageStart + pageSize - 1);

  return (
    <div className="pagination-controls">
      {totalViews > 5 && (
        <IconButton
          icon="arrow-1-left"
          disabled={!hasPrevPage}
          onClick={getPrevPage}
          size="small"
        />
      )}
      <span className="pagination-controls__text">
        {pageStart} - {pageEnd} of {totalViews}
      </span>
      {totalViews > 5 && (
        <IconButton
          icon="arrow-1-right"
          disabled={!hasNextPage}
          onClick={getNextPage}
          size="small"
        />
      )}
    </div>
  );
}

PaginationControls.propTypes = {
  getNextPage: PropTypes.func.isRequired,
  getPrevPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalViews: PropTypes.number.isRequired,
};

export default PaginationControls;
