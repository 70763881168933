import React from 'react';

import gradientArrowUpRightIcon from 'assets/svg/gradient-arrow-up-right.svg';

import './OnboardingMessage.css';

function OnboardingMessage() {
  const lines = ['Copy your video link to share', 'with others!'];

  return (
    <div className="sharing-onboarding-message">
      <img
        alt=""
        className="sharing-onboarding-message__arrow"
        src={gradientArrowUpRightIcon}
      />
      <span className="sharing-onboarding-message__text-container">
        <span className="sharing-onboarding-message__text">
          {lines.map((line, index) => (
            <span key={index} className="sharing-onboarding-message__line">
              {line}
              <br />
            </span>
          ))}
        </span>
      </span>
    </div>
  );
}

export default OnboardingMessage;
