import React from 'react';
import { Handles, Rail, Slider, Ticks,Tracks } from 'react-compound-slider';

import PropTypes from 'prop-types';

import { Handle, Tick,Track } from './components';

import './styles.css';

function CreateGifSlider({ values, onChange, domain }) {
  if (values[0] === undefined || values[1] === undefined) {
    return null;
  }

  return (
    <div className="create-gif-slider-container">
      <Slider
        className="create-gif-slider"
        mode={3}
        step={Math.min(1, domain[1] / 10)}
        domain={domain}
        onChange={onChange}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <div className="create-gif-slider__rail" {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={10}>
          {({ ticks }) => (
            <div>
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
}

CreateGifSlider.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
  domain: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

export default CreateGifSlider;
