import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { Icon,ToolTip } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';

const AllowChatLabel = ({ permissionsLoading, permissions }) => {
  const { user } = useContext(UserContext);
  if (permissionsLoading) {
    return null;
  }

  const isPublicVideo = permissions.length === 0;

  if (!isPublicVideo && user?.driftUserData.hasVideoPrivacy) {
    return (
      <ToolTip
        tethered
        hover
        flow="up"
        render={() => (
          <div>
            Because this link is set to a private
            <br />
            sharing setting, we’ve defaulted
            <br />
            to turning off chat. All admins of
            <br />
            your organization can see chats
            <br />
            started on private videos.
          </div>
        )}
      >
        <Icon name="info" width={14} height={14} />
      </ToolTip>
    );
  } else {
    return null;
  }
};

AllowChatLabel.propTypes = {
  permissions: PropTypes.array,
  permissionsLoading: PropTypes.bool.isRequired,
};

export default AllowChatLabel;
