import React from 'react';

import PropTypes from 'prop-types';

import { Avatar } from '@driftt/tide-core';

import './PeopleWithEmails.css';

function PeopleWithEmails({ description, permissions }) {
  return (
    <div className="people-with-emails">
      <div className="link-privacy__button-text">{description}</div>
      <ul className="people-with-emails__avatar-list">
        {permissions.map((permission) => {
          if (permission.source === 'self') {
            return null;
          }
          const emailOrDomain = permission.value[permission.source] || '';
          return (
            <li className="people-with-emails__avatar-list-item" key={permission.id}>
              <Avatar
                user={{
                  id: permission.id,
                  email: emailOrDomain.replace(/^@/g, ''),
                }}
              />
              {emailOrDomain}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

PeopleWithEmails.propTypes = {
  description: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.object,
    }),
  ),
};

export default PeopleWithEmails;
