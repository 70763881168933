import React, { useEffect, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import abLoopPlugin from 'videojs-abloop';

import {
  addCaptionsToPlayer,
  addPreviewThumbnailsToPlayer,
  ControlBarChildren,
} from 'utils/playerUtils';

import addHooksToVideoPlayer from './addHooksToVideoPlayer';

import 'videojs-hotkeys/videojs.hotkeys';

import 'video.js/dist/video-js.css';

abLoopPlugin(window, videojs);

function VideoJsWrapper({
  isAudioIncluded,
  onPlayerCanPlay,
  onPlayerError,
  thumbnailUrl,
  videoSources,
  isTrimmingVideo,
  onAdjustTrim,
  startTime,
  endTime,
  isCreatingGif,
  onCreateGifInit,
  gifStart,
  gifEnd,
  onAdjustCrop,
  hasCaptions,
}) {
  const player = useRef(null);
  const playerState = useRef({});
  const videoElement = useRef(null);

  useEffect(() => {
    player.current = videojs(videoElement.current, {
      autoplay: false,
      controls: true,
      fill: true,
      playbackRates: [0.5, 0.8, 1, 1.2, 1.3, 1.5, 2],
      controlBar: {
        pictureInPictureToggle: false,
        children: ControlBarChildren,
      },
      plugins: {
        hotkeys: {
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false,
          enableVolumeScroll: false,
        },
        abLoopPlugin: {
          enabled: false,
          loopIfBeforeStart: false,
          loopIfAfterEnd: false,
          pauseAfterLooping: false,
          pauseBeforeLooping: false,
          createButtons: false,
        },
        thumbnails: {},
      },
    });

    addHooksToVideoPlayer({
      player: player.current,
      state: playerState.current,
      onAdjustTrim,
      onAdjustCrop,
    });

    return () => player.current.dispose();
  }, [onAdjustTrim, onAdjustCrop]);

  useEffect(() => {
    player.current.src(videoSources);
    addCaptionsToPlayer(player.current, videoSources, hasCaptions);
    addPreviewThumbnailsToPlayer(player.current, videoSources);
  }, [videoSources, hasCaptions]);

  useEffect(() => {
    const handler = (event) => onPlayerCanPlay({ event, player: player.current });
    player.current.on('canplay', handler);

    return () => player.current.off('canplay', handler);
  }, [onPlayerCanPlay]);

  useEffect(() => {
    const handler = (event) => {
      onPlayerError({
        event,
        player: player.current,
        error: player.current.error(),
      });
    };
    player.current.on('error', handler);

    return () => player.current.off('error', handler);
  }, [onPlayerError]);

  useEffect(() => {
    player.current.poster(thumbnailUrl);
  }, [thumbnailUrl]);

  useEffect(() => {
    player.current.muted(!isAudioIncluded);
    playerState.current.isAudioDisabled = !isAudioIncluded;
  }, [isAudioIncluded]);

  useEffect(() => {
    if (isTrimmingVideo) {
      player.current.enableTrimMode(startTime, endTime);
    } else {
      player.current.disableTrimMode(startTime, endTime);
    }
  }, [startTime, endTime, isTrimmingVideo]);

  useEffect(() => {
    if (isCreatingGif) {
      player.current.enableCreateGifMode().then(onCreateGifInit);
    } else {
      player.current.disableCreateGifMode();
    }
  }, [isCreatingGif, onCreateGifInit]);

  useEffect(() => {
    player.current.setGifEnd(gifEnd);
  }, [gifEnd]);

  useEffect(() => {
    player.current.setGifStart(gifStart);
  }, [gifStart]);

  return (
    <div data-vjs-player className="video-container">
      <div
        className={cx('video-container-inner', {
          'audio-disabled': !isAudioIncluded,
          'videojs-trim': startTime !== null || endTime !== null || isTrimmingVideo,
        })}
      >
        <video ref={videoElement} className="video-js vjs-big-play-centered" playsInline />
      </div>
    </div>
  );
}

VideoJsWrapper.propTypes = {
  isAudioIncluded: PropTypes.bool.isRequired,
  onPlayerCanPlay: PropTypes.func.isRequired,
  onPlayerError: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  videoSources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  isTrimmingVideo: PropTypes.bool.isRequired,
  onAdjustTrim: PropTypes.func.isRequired,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  isCreatingGif: PropTypes.bool.isRequired,
  onCreateGifInit: PropTypes.func.isRequired,
  gifStart: PropTypes.number,
  gifEnd: PropTypes.number,
  onAdjustCrop: PropTypes.func.isRequired,
  hasCaptions: PropTypes.bool.isRequired,
};

export default VideoJsWrapper;
