import * as React from 'react';
import { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import * as S3 from 'aws-sdk/clients/s3';
import { UploadedFile } from 'types/interfaces';

import {
  A,
  Button,
  CardContainer,
  CircleLoader,
  H4,
  H5,
  Icon,
  Input,
  Span,
} from '@driftt/tide-core';

import { createTranscribe, regenerateGifCaptions, uploadCaptions } from 'api';
import BackArrow from 'components/backArrow';
import { UserContext } from 'context/UserContext';
import { UserCustomizationsContext } from 'context/UserCustomizationContext';
import { downloadCaptions } from 'utils';
import { segmentTrack } from 'utils/metrics';

import UploadCaptions from './UploadCaptions';

import './styles.css';

interface AudioAndCaptionsProps {
  isCaptionsEnabled: boolean | undefined; // undefined means no value was set, so we default to true
  setCaptionEnabled: (val: boolean) => void;
  isAudioIncluded: boolean;
  onAudioIncludedChange: (val: boolean) => void;
  videoId: number;
  hasCaptionsProcessed: boolean;
  captionsFileName: string | undefined;
  onClose: () => void;
}

const AudioAndCaptions: React.FC<AudioAndCaptionsProps> = ({
  isCaptionsEnabled,
  setCaptionEnabled,
  isAudioIncluded = true,
  onAudioIncludedChange,
  videoId,
  hasCaptionsProcessed,
  captionsFileName,
  onClose,
}) => {
  const { userHasFeature, createUrlPath } = useContext(UserContext);
  const { userCustomization } = useContext(UserCustomizationsContext);

  const userDisabledCaptions = userCustomization?.disableCaptions;

  const userHasManualTranscribe =
    userHasFeature('HAS_MANUAL_TRANSCRIBE') && !userHasFeature('ENABLE_INTERACTIVE_TRANSCRIPTS');
  const defaultCaptionEnabled =
    isCaptionsEnabled === undefined
      ? !userHasManualTranscribe && !userDisabledCaptions
      : isCaptionsEnabled;
  const showLoadingState = defaultCaptionEnabled && !hasCaptionsProcessed;

  const [saving, setSaving] = useState(false);

  const [isAudioToggleOn, setIsAudioToggleOn] = useState(isAudioIncluded);
  const [captionsToggle, setCaptionsToggle] = useState(defaultCaptionEnabled);

  const [file, setFile] = useState<UploadedFile | null>(null);

  const saveCaptionsAndRegenerateGifCaption = async () => {
    if (file && captionsFileName) {
      try {
        await uploadCaptions({
          S3,
          file,
          videoId,
          captionsKey: captionsFileName,
          onProgress: () => {},
        });
        await regenerateGifCaptions(videoId, captionsFileName);
        window.location.reload();
      } catch (e) {
        Error((e as Error).message);
      }
    }
  };

  const saveOption = async () => {
    setSaving(true);
    onAudioIncludedChange(isAudioToggleOn);
    setCaptionEnabled(captionsToggle);
    await saveCaptionsAndRegenerateGifCaption();
    setSaving(false);
    if (isCaptionsEnabled === undefined && userHasManualTranscribe) {
      segmentTrack('Captions Manually Enabled');
      createTranscribe(videoId);
    } else {
      onClose();
    }
  };

  const saveDisabled =
    captionsToggle === defaultCaptionEnabled &&
    isAudioToggleOn === isAudioIncluded &&
    file === null;

  return (
    <CardContainer padding="large" className="audio-and-captions-container">
      <BackArrow onClick={onClose} />
      <H4 className="general-settings-header">Audio and captions</H4>
      <div className="toggle-wrapper">
        <Input
          type="checkbox"
          label="Include audio"
          checked={isAudioToggleOn}
          onChange={(newValue) => {
            setIsAudioToggleOn(newValue);
          }}
        />
      </div>
      <div className="toggle-wrapper">
        <Input
          type="checkbox"
          label="Include captions"
          checked={captionsToggle}
          onChange={(newValue) => {
            segmentTrack('Captions Toggled CTA Clicked', {
              videoId: videoId,
              captionsOn: !captionsToggle,
            });
            setCaptionsToggle(newValue);
          }}
          disabled={!userHasManualTranscribe && !hasCaptionsProcessed}
        />
        {showLoadingState && <CircleLoader className="captions-loader" size="small" />}
      </div>
      {showLoadingState && (
        <p className="captions-loading">Captions may take several minutes to appear</p>
      )}
      {userDisabledCaptions && (
        <div className="user-disabled-captions">
          <Icon className="info" name="info" height="14" />
          <div>
            You have set captions to be disabled by default.{' '}
            <A href={createUrlPath('/settings/account')}>Update your settings</A>
          </div>
        </div>
      )}
      {userHasFeature('HAS_CAPTION_DOWNLOAD') && (
        <CSSTransition in={captionsToggle} timeout={300} classNames="captions-upload" unmountOnExit>
          <div>
            <H5 className="upload-captions-header">Upload captions</H5>
            <div className="upload-captions-text">
              Want to override the captions Drift Video has generated? You can upload your own!
            </div>
            {captionsFileName && <UploadCaptions file={file} setFile={setFile} videoId={videoId} />}
            <div className="download-text">
              <div className="tip-pointer">
                <span role="img" aria-label="hand-emoji">
                  👉
                </span>
              </div>
              <div>
                Tip: Want to make edits to the captions?
                <Span
                  className="download-captions"
                  onClick={() => {
                    segmentTrack('Captions Downloaded CTA Clicked', {
                      videoId: videoId,
                      captionsFileName: captionsFileName,
                    });
                    downloadCaptions(videoId);
                  }}
                >
                  {' '}
                  Download the current file,{' '}
                </Span>
                make your changes, and re-upload the file!
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
      <CSSTransition in={!saveDisabled} timeout={300} classNames="captions-upload" unmountOnExit>
        <footer className="footer">
          <Button className="save" type="primary" onClick={saveOption}>
            {saving ? 'Saving' : 'Save'}
            {saving && <CircleLoader className="captions-loader" size="small" />}
          </Button>
          <Button className="cancel" type="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </footer>
      </CSSTransition>
    </CardContainer>
  );
};

export default AudioAndCaptions;
