const ANYONE_WITH_LINK = -1;
const SPECIFIC_EMAILS = -2;

export function getSelectedOption(permissions) {
  if (permissions.length === 0) {
    return ANYONE_WITH_LINK;
  }

  const teamOption = permissions.find((p) => p.source === 'Privacy Team');

  if (teamOption) {
    return teamOption.value.team;
  }

  return SPECIFIC_EMAILS;
}

export function toOptions({ permissions, privacyGroups = [] }) {
  return [
    {
      value: ANYONE_WITH_LINK,
      title: 'Anyone with the link',
    },
    ...privacyGroups.map((group) => ({
      value: group.id,
      title: `Anyone at ${group.teamName} with the link`,
      isGroup: true,
      groupName: group.teamName,
    })),
    {
      value: SPECIFIC_EMAILS,
      title: 'People with specific email addresses',
      emailList: permissions
        .filter((p) => ['email', 'domain'].includes(p.source))
        .map((p) => p.value[p.source]),
    },
  ];
}

export function toPermissions(groupId) {
  const permissions = [];

  if (groupId !== ANYONE_WITH_LINK) {
    permissions.push({ source: 'self' });
  }

  if (groupId !== ANYONE_WITH_LINK && groupId !== SPECIFIC_EMAILS) {
    permissions.push({
      source: 'Privacy Team',
      value: { team: groupId },
    });
  }

  return permissions;
}

export function toEmailPermissions(emailAndDomainList) {
  return emailAndDomainList.map((item) => {
    const source = item.startsWith('@') ? 'domain' : 'email';

    return {
      source,
      value: { [source]: item },
    };
  });
}
