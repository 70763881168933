import * as React from 'react';
import { useEffect, useRef } from 'react';

import * as S3 from 'aws-sdk/clients/s3';
import { Thumbnails,UploadedThumbnailFile } from 'types/interfaces';

import { Colorway, Icon } from '@driftt/tide-core';

import { uploadThumbnail } from 'api';

import './styles.css';

interface UploadProgressProps {
  file: UploadedThumbnailFile | null;
  onThumbnailUrlRetrieved: () => void;
  videoId: number;
  tempCustomThumbnailUrl: string;
  setTempCustomThumbnailUrl: (url: string) => void;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
}

const UploadProgress: React.FC<UploadProgressProps> = ({
  file,
  videoId,
  onThumbnailUrlRetrieved,
  setUnsavedUnfurlThumbnail,
  tempCustomThumbnailUrl,
  setTempCustomThumbnailUrl,
}) => {
  const wasCancelled = useRef(false);

  useEffect(() => {
    let isSubscribed = true;
    const doUpload = async () => {
      try {
        const { Location } = await uploadThumbnail({
          S3,
          file,
          videoId,
          onProgress: () => {},
        });

        if (!wasCancelled.current && isSubscribed) {
          onThumbnailUrlRetrieved();
          setTempCustomThumbnailUrl(Location);
          setUnsavedUnfurlThumbnail({
            default: true,
            src: Location,
            type: 'custom_thumbnail',
          });
        }
      } catch (e) {
        Error((e as any).message);
      }
    };

    doUpload();
    return () => {
      isSubscribed = false;
    };
  }, [
    file,
    videoId,
    setTempCustomThumbnailUrl,
    tempCustomThumbnailUrl,
    setUnsavedUnfurlThumbnail,
    onThumbnailUrlRetrieved,
  ]);

  return (
    <>
      <Colorway className="uploading-colorway" type="3">
        <Icon name="cw-document" />
      </Colorway>
      <div className="uploading-text">Uploading...</div>
    </>
  );
};

export default UploadProgress;
