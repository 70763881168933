import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { CircleLoader } from '@driftt/tide-core';

import FullPageSpinner from 'components/fullPageSpinner';
import { generateShareUrl } from 'utils';

import MetricsContext from '../../../../context/MetricsContext';

import CreateGifButtons from './CreateGifButtons';
import CreateGifSlider from './CreateGifSlider';
import TrimButtons from './TrimButtons';
import VideoJsWrapper from './VideoJsWrapper';

import './styles.css';

function VideoEditor({
  loading,
  onPlayerCanPlay,
  onPlayerError,
  videoData,
  isTrimmingVideo,
  setIsTrimmingVideo,
  onVideoTrimmed,
  isCreatingGif,
  setIsCreatingGif,
  onSaveCreatedGif,
  onTrimVideoClose,
  hasCaptions,
}) {
  const { addBreadcrumb } = useContext(MetricsContext);
  useEffect(() => {
    if (!loading && videoData.videoSources.length > 0) {
      addBreadcrumb({ category: 'ui.load', message: 'Video sources loaded' });
    }
  }, [addBreadcrumb, loading, videoData]);

  const trimState = useRef({ startTime: null, endTime: null });

  const handleAdjustTrim = useCallback(({ startTime, endTime }) => {
    trimState.current = { startTime, endTime };
  }, []);

  const [gifTrim, setGifTrim] = useState({
    values: [],
    domain: [],
  });

  const handleCreateGifInit = useCallback(({ duration }) => {
    setGifTrim({
      values: [0, duration],
      domain: [0, duration],
    });
  }, []);

  const gifCropState = useRef({
    gifCropX: null,
    gifCropY: null,
    gifCropWidth: null,
    gifCropHeight: null,
  });

  const handleAdjustCrop = useCallback(({ cropX, cropY, cropWidth, cropHeight }) => {
    gifCropState.current = {
      gifCropX: cropX,
      gifCropY: cropY,
      gifCropWidth: cropWidth,
      gifCropHeight: cropHeight,
    };
  }, []);

  const [showSpinner, setShowSpinner] = useState(false);

  if (loading) {
    return (
      <div className="video-editor">
        <div className="drift-video dark-mode">
          <div className="share-page-video-player__loading">
            <CircleLoader size="large" colorMode="light" />
          </div>
        </div>
      </div>
    );
  }

  const { isAudioIncluded, thumbnailUrl, videoSources, startTime, endTime } = videoData;

  return (
    <div className="video-editor">
      <div className="drift-video dark-mode">
        <VideoJsWrapper
          isAudioIncluded={isAudioIncluded}
          isTrimmingVideo={isTrimmingVideo}
          onPlayerCanPlay={onPlayerCanPlay}
          onPlayerError={onPlayerError}
          thumbnailUrl={thumbnailUrl}
          videoSources={videoSources}
          startTime={startTime}
          endTime={endTime}
          onAdjustTrim={handleAdjustTrim}
          isCreatingGif={isCreatingGif}
          onCreateGifInit={handleCreateGifInit}
          gifStart={gifTrim.values[0]}
          gifEnd={gifTrim.values[1]}
          onAdjustCrop={handleAdjustCrop}
          hasCaptions={hasCaptions}
        />
      </div>
      {isTrimmingVideo && (
        <TrimButtons
          onSaveClick={() => {
            onVideoTrimmed(trimState.current);
            setIsTrimmingVideo(false);
            onTrimVideoClose();
          }}
          onCancelClick={() => {
            setIsTrimmingVideo(false);
            onTrimVideoClose();
          }}
        />
      )}
      {isCreatingGif && (
        <div>
          <CreateGifSlider
            domain={gifTrim.domain}
            values={gifTrim.values}
            onChange={(nextValues) => setGifTrim((gifTrim) => ({ ...gifTrim, values: nextValues }))}
          />
          <CreateGifButtons
            onSaveClick={() => {
              setShowSpinner(true);

              onSaveCreatedGif({
                gifStartTime: gifTrim.values[0],
                gifEndTime: gifTrim.values[1],
                ...gifCropState.current,
              }).then((data) => {
                window.location = generateShareUrl(data.shareUrl);
              });
            }}
            onCancelClick={() => {
              setIsCreatingGif(false);
            }}
          />
        </div>
      )}
      <FullPageSpinner show={showSpinner} />
    </div>
  );
}

VideoEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPlayerCanPlay: PropTypes.func.isRequired,
  onPlayerError: PropTypes.func.isRequired,
  videoData: PropTypes.shape({
    isAudioIncluded: PropTypes.bool.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    videoSources: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }),
  isTrimmingVideo: PropTypes.bool.isRequired,
  setIsTrimmingVideo: PropTypes.func.isRequired,
  onVideoTrimmed: PropTypes.func.isRequired,
  isCreatingGif: PropTypes.bool.isRequired,
  setIsCreatingGif: PropTypes.func.isRequired,
  onSaveCreatedGif: PropTypes.func.isRequired,
  onTrimVideoClose: PropTypes.func.isRequired,
  hasCaptions: PropTypes.bool.isRequired,
};

export default VideoEditor;
