import React from 'react';

import PropTypes from 'prop-types';

import { Input } from '@driftt/tide-core';

import AllowChatLabel from '../AllowChatLabel';

import './styles.css';

function DisableChatControl({ permissions, permissionsLoading, onChatControlChange, chatEnabled }) {
  const isPublicVideo = !permissionsLoading && permissions.length === 0;

  return (
    <div className="toggle-wrapper disable-chat">
      <Input
        type="checkbox"
        checked={chatEnabled}
        onChange={(value) => {
          onChatControlChange(!value);
        }}
      />
      <p className="toggle-text">
        Allow viewers to chat on this video
        {isPublicVideo && ' (recommended)'}
      </p>
      <div className="toggle-tooltip allow-chat-label">
        <AllowChatLabel permissions={permissions} permissionsLoading={permissionsLoading} />
      </div>
    </div>
  );
}

DisableChatControl.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.object,
    }),
  ),
  permissionsLoading: PropTypes.bool.isRequired,
  onChatControlChange: PropTypes.func.isRequired,
  chatEnabled: PropTypes.bool.isRequired,
};

export default DisableChatControl;
