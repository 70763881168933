import React from 'react';

import PropTypes from 'prop-types';

import { formatTime } from 'utils';

export const Handle = ({ domain: [min, max], handle: { id, value, percent }, getHandleProps }) => (
  <div
    className="create-gif-slider__handle"
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{ left: `${percent}%` }}
    {...getHandleProps(id)}
  />
);

Handle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number),
  handle: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    percent: PropTypes.number,
  }),
  getHandleProps: PropTypes.func,
}

export const Track = ({ source, target, getTrackProps }) => (
  <div
    className="create-gif-slider__track"
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
);

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    percent: PropTypes.number,
  }),
  target: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    percent: PropTypes.number,
  }),
  getTrackProps: PropTypes.func,
}

export const Tick = ({ tick, count }) => (
  <div>
    <div
      className="create-gif-slider__tick-mark"
      style={{
        left: `${tick.percent}%`,
      }}
    />
    <div
      className="create-gif-slider__tick-text"
      style={{
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
      }}
    >
      {formatTime(tick.value)}
    </div>
  </div>
);

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    percent: PropTypes.number,
  }),
  count: PropTypes.number,
}


