import * as React from 'react';
import { useState } from 'react';

import { Thumbnails, UploadedThumbnailFile } from 'types/interfaces';

import { InlineWarning } from '@driftt/tide-core';
import { UploadArea } from '@driftt/tide-file-upload';

import './styles.css';

interface MakeSelectionProps {
  file: UploadedThumbnailFile | null;
  setFile: (file: UploadedThumbnailFile | null) => void;
  onUploadingThumbnail: () => void;
  setTempCustomThumbnailUrl: (url: string) => void;
  setUnsavedUnfurlThumbnail: (thumbnail: Thumbnails) => void;
  defaultThumbnailAfterDeletion: Thumbnails;
}

const MakeSelection: React.FC<MakeSelectionProps> = ({
  file,
  setFile,
  onUploadingThumbnail,
  setTempCustomThumbnailUrl,
  setUnsavedUnfurlThumbnail,
  defaultThumbnailAfterDeletion,
}) => {
  const [errorText, setErrorText] = useState<string>('');

  return (
    <>
      <UploadArea
        className="upload-thumbnail-modal__file-upload"
        type="image"
        onReadError={() => setErrorText('There was a problem reading the file.')}
        onTypeError={() => setErrorText('File extension not supported.')}
        onSizeError={() => setErrorText('File exceeds maximum file size of 20MB.')}
        handleSaveFile={() => {}}
        handleClearFile={() => {
          setFile(null);
          setTempCustomThumbnailUrl('');
          setUnsavedUnfurlThumbnail(defaultThumbnailAfterDeletion);
        }}
        handleUploadFile={(file) => {
          setErrorText('');

          if (file.size === 0) {
            return Promise.reject(new Error('The file is empty.'));
          }

          setFile(file);
          onUploadingThumbnail();
          return Promise.resolve({ downloadUrl: file.preview });
        }}
        previewUrl={file && file.preview}
      />
      {errorText !== '' && <InlineWarning type="warning" copy={errorText} />}
    </>
  );
};

export default MakeSelection;
